import  { EloquentService } from "@/services/api.service"

const state = {
    search: '',
    viewModel: null,
    additionalParams: {},
    selectedModel: {},
    validationErrors: {},
    module: '',
    eloquentOptions: null,
    newEloquentObject: null,
    selectModels: {},
    warehouses: {},
    filters: {},
    filtersCache: window.localStorage.getItem('filtersCached') ? JSON.parse(window.localStorage.getItem('filtersCached')) : {},
    sortingCache: window.localStorage.getItem('sortingCached') ? JSON.parse(window.localStorage.getItem('sortingCached')) : {},
    dataTableCounter: 1,
    saveFilterCounter: 1,
    showModal: false,
    modalMode: 'edit',
    fullCard: true,
    formTabActive: null,
    visitToRecover: null
}

const getters = {
    modelName(state) {
        // return state.viewModel ? state.viewModel.split('\\').slice(-1)[0] : ''
        return state.viewModel
    },
}

const mutations = {
    setSearch(state, search) {
        state.search = search
    },
    setViewModel(state, model) {
        state.viewModel = model
    },
    setSelectModels(state, models) {
        state.selectModels = models
    },
    setSelectedModel(state, model) {
        state.selectedModel = model
    },
    setValidationErrors(state, errors) {
        state.validationErrors = errors
    },
    cleanValidationErrors(state) {
        state.validationErrors = {}
    },
    setShowModal(state, show) {
        state.showModal = show
    },
    setModule(state, module) {
        state.module = module
    },
    setFilters(state, filters) {
        state.filters = filters
    },
    setFullCard(state, value) {
        state.fullCard = value
    },
    setFormTabActive(state, value) {
        state.formTabActive = value
    },
    setFiltersCache(state, data) {
        let fils = {}
        for(let f in data.value) {
            if ({}.hasOwnProperty.call(data.value, f)) {
                let filter = JSON.parse(JSON.stringify(data.value[f]))
                // delete filter.condition // ?? why don't we keep it?
                fils[f] = filter
            }
        }
        state.filtersCache[data.id] = fils
        window.localStorage.setItem('filtersCached', JSON.stringify(state.filtersCache))
    },
    setSortingCache(state, data) {
        let fils = {}
        for(let f in data.value) {
            if ({}.hasOwnProperty.call(data.value, f)) {
                let filter = JSON.parse(JSON.stringify(data.value[f]))
                fils[f] = filter
            }
        }
        state.sortingCache[data.id] = fils
        window.localStorage.setItem('sortingCached', JSON.stringify(state.sortingCache))
    },
    setEloquentOptions(state, options) {
        state.eloquentOptions = options
    },
    incrementDataTableCounter(state) {
        state.dataTableCounter++
    },
    incrementSaveFilterCounter(state) {
        state.saveFilterCounter++
    },
    setWarehouses(state, warehouses) {
        state.warehouses = warehouses
    },
    setAdditionalParams(state, params) {
        state.additionalParams = params
    },
    setNewObject(state, object) {
        state.newEloquentObject = object
    },
    setModalMode(state, value) {
        state.modalMode = value
    },
    setVisitToRecover(state, value) {
        state.visitToRecover = value
    }
}

const actions = {
    deleteModel({ commit, state }, { id, model = null, superadmin = false }) {
        return new Promise(resolve => {
            EloquentService.delete(model ?? state.viewModel, id, superadmin).then(() => {
                commit('setSelectedModel', {})
                commit('incrementDataTableCounter')
                resolve()
            }).catch((e) => {
                console.log(e)
            })
        })
    },
    deleteModelBulk({ commit, state }, { ids = [], model = null, superadmin = false }) {
        return new Promise(resolve => {
            EloquentService.deleteBulk(model || state.viewModel, ids, superadmin).then(() => {
                commit('setSelectedModel', {})
                commit('incrementDataTableCounter')
                resolve()
            }).catch((e) => {
                console.log(e)
            })
        })
    },
    getWarehouses({commit}) {
        return new Promise(resolve => {
            EloquentService.autocomplete('warehouse', '', 999).then((res) => {
                commit('setWarehouses', res.data.result)
                resolve(res.data.result)
            }).catch((e) => {
                console.log(e)
            })
        })
    },
    dataTableLogout(context) {
        context.commit('setFilters', {})
        context.commit('setFiltersCache', {})
        window.localStorage.removeItem('filtersCached')
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
